import Swal from 'sweetalert2';
import resetAuth from '../mutations/auth/reset';

const jwt = require('jsonwebtoken');

/**
 * Fetch API base URL, based on the selected region.
 *
 * @param {String} region API region
 * @param {String} region API env (either prod, demo or staging)
 * @returns {String} API Base URL
 */
const fetchEndpoint = (region, env) => {
  const reg = region.split('-')[0];

  if (env === 'prod') {
    return `https://api-${reg}.skipsolabs.com/clients`;
  }

  return `https://api-${reg}.${env}.skipsolabs.com/clients-${env}`;
};

const fetchMicroservicesEndpoint = (region, env) => {
  const reg = region.split('-')[0];

  if (env === 'prod') {
    return `https://${env}-api-${reg}.skipsolabs.com/clients/microservices/list`;
  }

  return `https://api-${reg}.${env}.skipsolabs.com/clients-${env}/microservices/list`;
};

export default {
  /**
   * Fetch API base URL, based on the selected region.
   *
   * @param {String} region API region
   * @param {String} region API env (either prod, demo or staging)
   * @returns {String} API Base URL
   */
  fetchEndpoint,
  fetchMicroservicesEndpoint,

  /**
   * Fetch deployment targets.
   *
   * @param {Array<String>} regions Deployment regions
   * @param {Object} environments Deployment environments (per region)
   * @returns {Array<Object>}
   */
  fetchDeploymentTargets(regions, environments) {
    const targets = [];

    for (let i = 0; i < regions.length; i += 1) {
      const region = regions[i];

      targets.push(
        ...environments[region].map((env) => fetchEndpoint(region, env))
      );
    }

    return targets;
  },

  /**
   * Fetch Cognito user token for authenticating API calls.
   *
   * @returns {String} User token
   */
  fetchToken() {
    return localStorage.getItem(`accessToken`);
  },

  /**
   * Fetch team user's email address.
   *
   * @returns {String} AWS Cognito access token
   */
  fetchEmail(accessToken = null) {
    if (!accessToken) {
      accessToken = localStorage.getItem(`accessToken`);
    }

    return jwt.decode(accessToken).email;
  },

  /**
   * Validate request status, intercepting unauthorized requests.
   *
   * @param {Object} request Request object
   */
  validateStatus: (status) => {
    if (status === 401 || status === 403) {
      resetAuth();
      location.href = '/';
    }

    return status === 401 || status === 403 || (status >= 200 && status < 300);
  },

  /**
   * Show a toast message via Swal2
   *
   * @param {String} icon success/warning/error
   * @param {String} text Text to show in the toast
   * @return {Promise<Swal>}
   */
  toast: (icon, text) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    return Toast.fire({
      icon,
      text,
    });
  },
};
